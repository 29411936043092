<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-form-group class="col-12" :label="$t('name')">
                    <ValidationProvider
                        name="name"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-input-group class="mb-3" >
                            <b-form-input
                                v-model="form.name"
                                :class="errors[0] ? 'is-invalid' : ''"
                            />
                        </b-input-group>
                        <b-form-invalid-feedback
                            v-if="errors[0]"
                            v-html="errors[0]"
                            class="mb-2"
                        />
                    </ValidationProvider>
                </b-form-group>
                <b-form-group class="col-12" :label="$t('address')">
                    <ValidationProvider
                        name="address"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-input-group class="mb-3" >
                            <b-form-input
                                v-model="form.address"
                                :class="errors[0] ? 'is-invalid' : ''"
                            >
                            </b-form-input>
                        </b-input-group>
                        <b-form-invalid-feedback
                            v-if="errors[0]"
                            v-html="errors[0]"
                            class="mb-2"
                        ></b-form-invalid-feedback>
                    </ValidationProvider>
                </b-form-group>

            </b-row>
            <div class="col-12 mt-3 d-flex">
                <b-button @click="createForm"
                          :disabled="formLoading"
                          type="button"
                          variant="primary"
                          class="btn-lg mr-2">
                    {{ $t('save').toUpperCase() }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>

    import {ValidationProvider, ValidationObserver} from "vee-validate"
    import CampusService from "@/services/CampusService";

    export default {
        components: {
            ValidationProvider, ValidationObserver
        },
        data() {
            return {
                formLoading: false,
                form: {
                    name: null,
                    address: null,
                }
            }
        },
        methods: {
            async createForm() {
                const isValid = await this.$refs.formModalValidate.validate()
                if (isValid) {
                    CampusService.store(this.form)
                                 .then((response) => {
                                     this.$toast.success(this.$t("api." + response.data.message));
                                     this.$emit("createFormSuccess")
                                 })
                                 .catch((error) => {
                                     this.showErrors(error, this.$refs.formModalValidate)
                                 });
                }
            }
        }
    }
</script>
